import { FeatureData } from '@innedit/innedit';
import { DocumentType, PageType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import IconEye from '../../../icons/Eye';
import IconEyeSlash from '../../../icons/EyeSlash';
import IconMinusSquare from '../../../icons/MinusSquare';
import IconPlusSquare from '../../../icons/PlusSquare';
import IconSquare from '../../../icons/Square';
import { colors } from '../../../styles/theme';
import ListItem from './index';

const ListItemPage: FC<ListItemProps<PageType>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [doc, setDoc] = useState<DocumentType<PageType>>();
  const [docs, setDocs] = useState<DocumentType<PageType>[]>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  useEffect(() => {
    let isMounted = true;
    if (isOpen && doc && doc.nbSubPages > 0) {
      const subModel = new FeatureData<PageType>({
        collectionName: 'pages',
        espaceId: model.espaceId,
      });
      subModel
        .find({
          wheres: {
            parent: docId,
          },
        })
        .then(newDocs => {
          if (isMounted) {
            setDocs(newDocs);
          }

          return true;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [doc, isOpen]);

  if (!doc) {
    return null;
  }

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault();

    setIsOpen(io => {
      if (io) {
        setDocs(undefined);
      }

      return !io;
    });
  };

  return (
    <ListItem
      after={
        docs &&
        docs.length > 0 && (
          <div className="pl-6 bg-light-400">
            {docs.map(d => (
              <ListItemPage
                key={d.id}
                docId={d.id}
                model={model}
                onClick={onClick}
              />
            ))}
          </div>
        )
      }
      className={className}
      contentClassName="flex flex-col justify-center"
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <div className="ml-3 flex items-center space-x-3 min-h-10.5">
        {doc.nbSubPages > 0 ? (
          <>
            {isOpen ? (
              <IconPlusSquare
                color={colors.light[700]}
                onClick={handleOnClick}
              />
            ) : (
              <IconMinusSquare
                color={colors.light[700]}
                onClick={handleOnClick}
              />
            )}
          </>
        ) : (
          <IconSquare color={colors.light[700]} />
        )}
        {doc.hidden && <IconEyeSlash />}
        {!doc.hidden && <IconEye />}
        <strong>{doc.name}</strong>
      </div>
    </ListItem>
  );
};

export default ListItemPage;
