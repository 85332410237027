/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '../../components/Icon';

const IconSquare: FC<IconType> = ({ className, color, size = 16 }) => (
  <svg className={className} height={size} viewBox="0 0 448 512" width={size}>
    <path
      d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconSquare;
