import { PageData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../../../../../components/List/Item/Page';
import CMSView from '../../../../../../components/View';
import List from '../../../../../../containers/Espace/List';
import params from '../../../../../../params/page/index.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PagesPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { channelId, espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          filters={compact([
            {
              libelle: 'Caché',
              multiple: true,
              name: 'hidden',
              options: [
                {
                  label: 'Oui',
                  value: true,
                },
                {
                  label: 'Non',
                  value: false,
                },
              ],
            },
          ])}
          itemList={Page}
          itemPathnamePrefix={`/espaces/${espaceId}/canaux/${channelId}/pages/`}
          model={
            new PageData({
              channelId,
              espaceId,
              params,
              wheres: {
                parent: '',
              },
            })
          }
          search={location.search}
          title={t('pages.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PagesPage);
